<template>
  <div class='container'>
    <div class="box">
      <div class="box-title">{{warnings[$route.query.level].title}}</div>
      <div class="tips">{{warnings[$route.query.level].subtitle}}</div>
    </div>
    <div class="content-box" style="background-color: #FEF9F3;">
        <div class="title" style="color: #FB6D3F;">学生信息</div>
        <p>姓名：{{risk.trueName}} <span style="margin-left: 10.6667vw;">校区：{{ risk.area ? risk.area : "未填写" }}</span> </p>
        <p style="margin-top: 2.6667vw;">年级：{{
                risk.grade !== null && risk.grade !== -1
                  ? (gradeList[risk.grade]?gradeList[risk.grade].gradeName:'')
                  : ""
              }} <span style="margin-left: 10.6667vw;">班级：{{ risk.studyClass }}</span> </p>
    </div>
    <div class="content-box" v-if="$route.query.level==1" style="background-color: #EFF9F8;">
        <div class="title" style="color:#5EDBC9">干预方案-三大任务</div>
        <div class="step">
          <div class="step-title">任务一</div>
          <h2 style="font-size: 5.3333vw;text-align: center;">约谈学生</h2>
          <div style="text-align: center;">
            <button @click="$router.push({path:'/warn/crisisTask',query:{id:$route.query.id,task:1}})">开始约谈</button>
          </div>
        </div>
        <div class="step">
          <div class="step-title">任务二</div>
          <h2 style="font-size: 5.3333vw;text-align: center;">通知班主任和家长</h2>
          <div style="text-align: center;">
            <button @click="$router.push({path:'/warn/crisisTask',query:{id:$route.query.id,task:2}})">前往通知</button>
          </div>
        </div>
        <div class="step">
          <div class="step-title">任务三</div>
          <h2 style="font-size: 5.3333vw;text-align: center;">持续跟踪</h2>
          <div style="text-align: center;">
            <button @click="$router.push({path:'/warn/crisisTask',query:{id:$route.query.id,task:3}})">立即跟踪</button>
          </div>
        </div>
    </div>
    <div class="content-box" v-else>
      <div class="title">谈方评估</div>
      <div style="text-align: center;">
        <img src="@/assets/my/assessment.png" alt="" style="width: 53.3333vw;height: 49.6vw;">
      </div>
      <p style="color: #666;line-height: 6.1333vw;">每个学生的评估时间需要10-30分钟，静态测评普查中存在异常的学生，可使用访谈评估对可能存在危机的学生进行个别访谈，确定危机等级。</p>
      <div style="text-align: center;margin: 5.3333vw 0;">
        <button class="btn" @click="isDialog=true">开始评估</button>
      </div>
    </div>
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/dialog-bg.png"  style="width: 40vw;height: 40vw;margin: 5.3333vw 0 4vw 0;"/>
        <p>移动端暂未开放评估功能，</p>
        <p>如需对学生进行心理评估请使用PC端进行评估</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {riskDetail} from '@/api/warning.js'
export default {
  data(){
    return{
      risk:{},
      level:null,
      isDialog:false,
      warnings: [
       {
         level: 1,
         title: '心理评估',
         subtitle: '量表复测的同时需结合心理访谈增加相应的心理测量手段进行综合评估，也可查看该学生是否有联动小组提交的观察记录。'
       }, {
         level: 2,
         title: '二级危机名单',
         subtitle: '学校层面应给予重点关注；当确认心理危机进一步升级时，按照三级预警处置，未达到三级预警的学生采用干预三大任务进行学生心理辅导'
       }
      ],
      gradeList: [
            {
              grade: 0,
              gradeName: '一年级'
            },
            {
              grade: 1,
              gradeName: '二年级'
            },
            {
              grade: 2,
              gradeName: '三年级'
            },
            {
              grade: 3,
              gradeName: '四年级'
            },
            {
              grade: 4,
              gradeName: '五年级'
            },
            {
              grade: 5,
              gradeName: '六年级'
            },
            {
              grade: 6,
              gradeName: '初一'
            },
            {
              grade: 7,
              gradeName: '初二'
            },
            {
              grade: 8,
              gradeName: '初三'
            },
            {
              grade: 9,
              gradeName: '高一'
            },
            {
              grade: 10,
              gradeName: '高二'
            },
            {
              grade: 11,
              gradeName: '高三'
            }
      ],
      
    }
  },
  created(){
    this.getDetail()
    this.level=this.$route.query.level
  },
  methods:{
    async getDetail(){
      const {id} = this.$route.query
      const res = await riskDetail({id})
      this.risk=res.data.risk
    }
 
  }
}
</script>
<style lang='less' scoped>
.container{
  padding:5.3333vw 0 4vw 0;
  min-height: 100%;
  background-color: #fff;
  .box{
    .box-title{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 4vw;
      font-size: 4.2667vw;
      font-weight: 550;
      color: #FB6D3F;
      // border-left: .8vw solid #FB6D3F;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: .8vw;
        height: 4vw;
        border-radius: .8vw;
        background-color: #FB6D3F;
      }
    }
    .tips{
      padding: 2.6667vw 4vw 0 4vw;
      color: #666;
      line-height: 5.6vw;
      font-size: 3.4667vw;
    }
  }
  .content-box{
    margin: 4vw 4vw 0 4vw;
    padding: 4vw;
    border-radius: 4vw;
    background-color: #EFF9F8;
    font-size: 3.7333vw;
    .title{
      color: #5EDBC9;
      font-weight: 550;
      font-size: 4.2667vw;
      margin-bottom: 4vw;
    }
    .step{
      margin-top: 4vw;
      padding: 2.6667vw 0;
      display: flex;
      height: 40vw;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      .step-title{
        width: 17.8667vw;
        height: 5.6vw;
        text-align: center;
        line-height: 5.6vw;
        color: #fff;
        background: #FB6D3F;
        border-radius: 0vw 5.3333vw 5.3333vw 0vw;
      }
      button{
        width: 26.6667vw;
        height: 9.6vw;
        color: #Fff;
        border-radius: 13.3333vw;
        background-color: #5EDBC9;
      }
    }
    .btn{
      width: 41.8667vw;
      height: 11.4667vw;
      color: #fff;
      border-radius: 13.3333vw;
      background-color: #5EDBC9
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      line-height: 6.4vw;
    }
    button{
      margin: 8vw;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #000;
    }
  }
}
</style>